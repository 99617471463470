import Vue from "vue";
import VueRouter from "vue-router";

const Resources = () => import("../pages/Resources.vue");
const Assets = () => import("../pages/Assets.vue");
const Asset = () => import("../pages/Asset.vue");
const Library = () => import("../pages/Library.vue");
const SupportRequests = () => import("../pages/SupportRequests.vue");
const Users = () => import("../pages/Users.vue");
const User = () => import("../pages/User.vue");
const Offices = () => import("../pages/Offices.vue");
const OfficeGroups = () => import("../pages/OfficeGroups.vue");
const Dashboard = () => import("../pages/Dashboard.vue");
const AssetQrCode = () => import("../components/assets/AssetQrCode.vue");
const Keys = () => import("../pages/Keys.vue");
const Offboard = () => import("../pages/Offboard.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Assets" },
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/assets",
    name: "Assets",
    component: Assets,
  },
  {
    path: "/library",
    name: "Library",
    component: Library,
  },
  {
    path: "/asset/:assetId",
    name: "Asset",
    component: Asset,
    props: true,
  },
  {
    path: "/keys",
    name: "Keys",
    component: Keys,
  },
  {
    path: "/assets/:uuid",
    name: "AssetUUID",
    component: Assets,
    props: true,
  },
  {
    path: "/asset/qr/:uuid",
    name: "AssetQrCode",
    component: AssetQrCode,
    props: true,
  },
  {
    path: "/support-requests",
    name: "SupportRequests",
    component: SupportRequests,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/users/:userId",
    name: "User",
    component: User,
    props: true,
  },
  {
    path: "/users/:userId/offboard",
    name: "Offboard",
    component: Offboard,
    props: true,
  },
  {
    path: "/offices",
    name: "Offices",
    component: Offices,
  },
  {
    path: "/officeGroups",
    name: "OfficeGroups",
    component: OfficeGroups,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
];

const router = new VueRouter({ routes });

export default router;
