import { DocumentResolver } from "./DocumentResolver";

/**
 * Returns the Human Readable Value(HRV) for a given role.
 *
 * @param {UserRole} role
 */
export function getHRVForUserRole(role) {
  const values = {
    [UserRole.ADMIN]: "Super-Admin",
    [UserRole.OFFICEADMIN]: "Admin",
    [UserRole.USER]: "User",
  };

  return values[role];
}

/**
 * @enum {string} UserRole
 */
export const UserRole = {
  ADMIN: "ADMIN",
  OFFICEADMIN: "OFFICEADMIN",
  USER: "USER",
};

/**
 * @typedef {object} User
 * @property {string} id
 * @property {string} displayName
 * @property {UserRole} role
 * @property {firestore.DocumentReference<firestore.DocumentData>} officeLocation
 * @property {firestore.DocumentReference<firestore.DocumentData>[]} administeredOffices
 * @property {firestore.DocumentReference<firestore.DocumentData>} leader
 * @property {firestore.DocumentReference<firestore.DocumentData>[]} assignedUsers
 * @property {string} photoUrl
 */
export class User extends DocumentResolver {
  /**
   *
   * @param {{
   *  id: string
   *  displayName: string
   *  email: string
   *  role: UserRole
   *  officeLocation: firestore.DocumentReference<firestore.DocumentData>
   *  administeredOffices: firestore.DocumentReference<firestore.DocumentData>[]
   *  leader: firestore.DocumentReference<firestore.DocumentData>
   *  assignedUsers: firestore.DocumentReference<firestore.DocumentData>[]
   *  photoUrl: string
   *  disabled: Boolean
   * }} parameters
   */
  constructor({
    id = "",
    displayName = "",
    email = "",
    role = UserRole.USER,
    officeLocation = null,
    administeredOffices = [],
    leader = null,
    assignedUsers = [],
    photoUrl = null,
    disabled = false,
  } = {}) {
    super();
    this.id = id;
    this.displayName = displayName;
    this.email = email;
    this.role = role;
    this.officeLocation = officeLocation;
    this.administeredOffices = administeredOffices;
    this.leader = leader;
    this.assignedUsers = assignedUsers;
    this.photoUrl = photoUrl;
    this.disabled = disabled;
  }

  /**
   * Returns the resolved ref data in an array structure.
   *
   * The return value can be destructured like:
   *
   * `const [leader, assignedUsers, officeLocation] = await user.resolveRefs();`
   *
   * @function
   * @async
   * @returns {[User, User[]]} data
   */
  async resolveRefs() {
    return Promise.all([
      this.resolveRef(User, "leader"),
      this.resolveListOfRefs(User, "assignedUsers"),
    ]);
  }

  /**
   * Adds assigned user to admin
   *
   * @function
   * @param {{
   *  userRefToPush: firestore.DocumentReference<firestore.DocumentData>
   * }} parameters
   */

  pushAssignedUser(userRefToPush) {
    if (this.assignedUsers.indexOf(userRefToPush) === -1)
      this.assignedUsers.push(userRefToPush);
  }

  /**
   * Removes assigned user by id
   *
   * @function
   * @param {{
   *  id: string
   * }} parameters
   */
  removeAssignedUser(id) {
    if (this.assignedUsers)
      this.assignedUsers = this.assignedUsers.filter((user) => user.id !== id);
  }
}
