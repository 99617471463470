<template>
  <div
    id="numberCounter"
    v-if="isNotificationVisible"
    :class="{
      circle: circle,
      fadeIn: fadeInEnabled,
      fadeOut: fadeOutEnabled,
      stretchAnimation: stretchEnabled,
    }"
  >
    {{ number }}
  </div>
</template>

<script>
import { ReactiveService, SupportRequestsService } from "@/services";
import {
  SupportRequestsFilter as SupportRequestFilterClass,
  SupportRequestStatus,
} from "@/models";

export default {
  name: "NumberOfNotifications",
  props: ["newFunction"],
  data() {
    return {
      isNotificationVisible: false,
      circle: true,
      fadeInEnabled: true,
      fadeOutEnabled: false,
      stretchEnabled: false,
      number: 0,
      overrideFunction: false,
      filter: ReactiveService.getReactive(
        "SupportRequestsFilter",
        new SupportRequestFilterClass()
      ),
      supportRequests: [],
      selector: null,
    };
  },
  mounted() {
    this.selector = document.getElementById("numberCounter");
  },
  watch: {
    // whenever number of notification changes, this function will run
    number: function (newNumber, oldNumber) {
      if (newNumber === 0 && oldNumber > 0) {
        this.fadeInEnabled = false;
        this.fadeOutEnabled = true;
        setTimeout(() => {
          this.fadeOutEnabled = false;
          this.isNotificationVisible = false;
        }, 2800);
      }
      if (newNumber > 0) {
        this.isNotificationVisible = true;
        if (oldNumber === 0) {
          this.fadeInEnabled = true;
          setTimeout(() => {
            this.fadeInEnabled = false;
          }, 2000);
        } else if (oldNumber !== newNumber) {
          this.stretchEnabled = true;
          setTimeout(() => {
            this.stretchEnabled = false;
          }, 2000);
        }
      }
    },
  },
  created() {
    setInterval(() => {
      this.number = this.newFunction
        ? this.newFunction
        : this.numberOfActiveSupportRequests();
    }, 1000);
  },
  methods: {
    async fetchSupportRequests() {
      this.supportRequests = await SupportRequestsService.getAllFromSupportRequestsFilter(
        this.filter
      );
    },
    numberOfActiveSupportRequests() {
      this.fetchSupportRequests();
      return this.supportRequests.filter(
        (request) =>
          request.status === SupportRequestStatus.PENDING ||
          request.status === SupportRequestStatus.CREATED
      ).length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/initial-variables.sass";

.circle {
  height: 20px;
  width: 20px;
  background-color: #e11a04;
  border-radius: 50%;

  position: absolute;
  top: -5px;
  right: 0;

  font-size: 15px;
  color: white;
  text-align: center;
}
.fadeIn {
  animation: fadeIn;
  animation-duration: 2s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  0% {
    background-color: #e11a04;
  }
  50% {
    height: 25px;
    width: 25px;
    background-color: #e11a04;
    top: -15px;
    box-shadow: #523239;
    font-size: 18px;
    text-align: center;
  }
  100% {
    background-color: #e11a04;
  }
}

.fadeOut {
  animation: fadeOut;
  animation-duration: 3s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.stretchAnimation {
  animation: stretchAnimation;
  animation-duration: 2s;
}
@keyframes stretchAnimation {
  50% {
    height: 25px;
    width: 25px;
    background-color: #e11a04;
    top: -5px;
    box-shadow: #523239;
    font-size: 18px;
    text-align: center;
  }
}

@media screen and (max-width: $desktop) {
  .circle {
    height: 20px;
    width: 20px;
    top: 0;
    right: 7px;
    font-size: 15px;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
    0% {
      background-color: #e11a04;
    }
    50% {
      height: 25px;
      width: 25px;
      background-color: #e11a04;
      top: 0;
      box-shadow: #523239;
      font-size: 18px;
      text-align: center;
    }
    100% {
      background-color: #e11a04;
    }
  }
  @keyframes stretchAnimation {
    50% {
      height: 25px;
      width: 25px;
      background-color: #e11a04;
      top: 0;
      box-shadow: #523239;
      font-size: 18px;
      text-align: center;
    }
  }
}
@media only screen and (max-width: $tablet) {
  .circle {
    height: 15px;
    width: 15px;
    top: 3px;
    right: 2px;
    font-size: 11px;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }

    0% {
      background-color: #e11a04;
    }
    50% {
      height: 23px;
      width: 23px;
      background-color: #e11a04;
      top: 0;
      box-shadow: #523239;
      font-size: 16px;
      text-align: center;
    }
    100% {
      background-color: #e11a04;
    }
  }
  @keyframes stretchAnimation {
    50% {
      height: 23px;
      width: 23px;
      background-color: #e11a04;
      top: 0;
      box-shadow: #523239;
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
