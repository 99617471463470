// eslint-disable-next-line no-unused-vars
import { AssetStatus } from "./Asset";

/**
 *
 * @param {{
 * resourceSearch: string;
 * resourceType: string;
 * assigneeSearch: string;
 * statusSearch: AssetStatus[];
 * fromDateOfPurchaseSearch: string;
 * toDateOfPurchaseSearch: string;
 * qr: string;
 * librarySearch : boolean
 * locationSearch : Office[]
 * }} parameters
 */
export class AssetsFilter {
  constructor({
    resourceId = "",
    resourceSearch = "",
    resourceType = "",
    assigneeSearch = "",
    statusSearch = ["ASSIGNED", "UNASSIGNED"],
    fromDateOfPurchaseSearch = "",
    toDateOfPurchaseSearch = "",
    qr = "",
    librarySearch = false,
    locationSearch = [],
  } = {}) {
    this.resourceId = resourceId;
    this.resourceSearch = resourceSearch;
    this.resourceType = resourceType;
    this.assigneeSearch = assigneeSearch;
    this.statusSearch = statusSearch;
    this.fromDateOfPurchaseSearch = fromDateOfPurchaseSearch;
    this.toDateOfPurchaseSearch = toDateOfPurchaseSearch;
    this.qr = qr;
    this.librarySearch = librarySearch;
    this.locationSearch = locationSearch;
  }
}
