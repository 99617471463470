import { FirestoreService } from "./FirestoreService";
import { AuthService } from "./AuthService";
import { UsersService } from "./UsersService";
import { ResourcesService } from "./ResourcesService";
// eslint-disable-next-line no-unused-vars
import { AssetsFilter, UserRole, Asset, Resource } from "@/models";
import { CacheService } from "./CacheService";
import { getDocs, query, where, or, and } from "firebase/firestore";

export class AssetsFirestoreService extends FirestoreService {
  /**
   * @async
   * @private
   * @param {firestore.DocumentReference<firestore.DocumentData>[]} resourceRefs
   * @return {Promise<String[]>} resourceReferencesAsString
   * @private
   */
  async _getResourceReferences(resourceRefs) {
    return resourceRefs
      .map((a) => (a = CacheService.getResource(a)))
      .map((r) => r.id);
  }

  /**
   * @async
   * @private
   * @param {Resource[]} resources
   * @param {Asset[]} assets
   * @return {Promise<Asset[]>} Assets[]
   */
  async _filterAssetResourceReferences(resources, assets) {
    let resourceRefs = assets.map((a) => a.resourceRef);
    let resourceReferencesAsString = await this._getResourceReferences(
      resourceRefs
    );
    let resourceIds = resources.map((resource) => resource.id);

    resourceReferencesAsString = resourceReferencesAsString.filter(
      (assetResourceRef) => {
        return resourceIds.includes(assetResourceRef);
      }
    );

    return assets.filter((a) =>
      resourceReferencesAsString.includes(a.resourceRef.id)
    );
  }

  getAssets() {
    if (AuthService.isAdmin()) {
      return this.getAll();
    } else {
      return this.getUserAssets(AuthService.getUserId());
    }
  }

  /**
   * @param {string} userId
   */
  getUserAssets(userId) {
    return this.getAllWhere("userRef", "==", UsersService.getDocById(userId));
  }

  getLoaners() {
    return this.getAllWhere("loaner", "==", true);
  }

  /**
   *
   * @param {AssetsFilter} filter
   */
  async getAllFromAssetsFilter(filter) {
    const whereClauses = [];

    if (!filter.librarySearch && AuthService.isUser()) {
      const userLocation = await UsersService.getUserOffice();
      whereClauses.push(
        or(
          where(
            "userRef",
            "==",
            UsersService.getDocById(AuthService.getUserId())
          ),
          and(
            where("isOfficeAsset", "==", true),
            where("officeLocation", "==", userLocation)
          )
        )
      );
    }

    if (filter.locationSearch.length > 0) {
      const officeObjectArray = filter.locationSearch.map((office) =>
        office.toRef()
      );
      whereClauses.push(where("officeLocation", "in", officeObjectArray));
    }

    if (AuthService.getUserRole() === UserRole.OFFICEADMIN) {
      const user = await UsersService.getOne(AuthService.getUserId());

      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices)
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }

    if (filter.resourceId) {
      whereClauses.push(
        where(
          "resourceRef",
          "==",
          ResourcesService.getDocById(filter.resourceId)
        )
      );
    }

    if (filter.statusSearch.length) {
      whereClauses.push(where("status", "in", filter.statusSearch));
    }

    if (filter.fromDateOfPurchaseSearch) {
      whereClauses.push(
        where("dateOfPurchase", ">=", filter.fromDateOfPurchaseSearch)
      );
    }

    if (filter.toDateOfPurchaseSearch) {
      whereClauses.push(
        where("dateOfPurchase", "<=", filter.toDateOfPurchaseSearch)
      );
    }

    if (filter.qr) {
      whereClauses.push(where("qr", "==", filter.qr));
    }

    if (filter.librarySearch) {
      whereClauses.push(where("loaner", "==", filter.librarySearch));
    }

    const q = query(this.getCollection(), and(...whereClauses));
    const resolvesDocs = await getDocs(q);
    let assets = this.toDocClasses(resolvesDocs);

    if (filter.assigneeSearch) {
      let users = await CacheService.getUsers();
      users = users.filter((user) => {
        const isDisplayNameMatch = user.displayName
          .toLowerCase()
          .includes(filter.assigneeSearch.toLowerCase());

        const isEmailMatch = user.email
          .toLowerCase()
          .includes(filter.assigneeSearch.toLowerCase());

        return isDisplayNameMatch || isEmailMatch;
      });

      let officeAssets = assets.filter((asset) => {
        const isOfficeNameMatch = CacheService.getOffice(asset.officeLocation)
          .HRV.toLowerCase()
          .includes(filter.assigneeSearch.toLowerCase());

        return (
          asset.isOfficeAsset &&
          (isOfficeNameMatch ||
            filter.assigneeSearch.toLowerCase().includes("office"))
        );
      });
      assets = await this._filterByUserRef(users, assets);
      assets = assets.concat(officeAssets);
    }

    if (filter.resourceSearch) {
      let resources = await CacheService.getResources();
      resources = resources.filter((resource) => {
        return (resource.manufacturer + " " + resource.model)
          .toLowerCase()
          .includes(filter.resourceSearch.toLowerCase());
      });

      assets = await this._filterAssetResourceReferences(resources, assets);
    }

    if (filter.resourceType) {
      let resources = await CacheService.getResources();
      resources = resources.filter((resource) => {
        return resource.type.includes(filter.resourceType.toUpperCase());
      });

      assets = await this._filterAssetResourceReferences(resources, assets);
    }
    return assets;
  }
}
