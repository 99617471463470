import "../firebase";
import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { UserRole } from "@/models";
import { UsersService } from "./UsersService";
import axios from "axios";
import "@/firebase/index.js";
import { LogService } from "@/services/LogService";
import { LogEntryData } from "@/models/LogEntryData";

export const AuthService = new (class {
  /**
   * @private
   */
  _state = {
    role: null,
  };

  /**
   * @private
   */
  unsubscribeUserRoleListener = null;

  /**
   * @private
   */
  userSnapshotLocationListener = () => undefined;

  /**
   * @readonly
   */
  getState() {
    return this._state;
  }

  /**
   * Updates the logged-in users location.
   * @param {string} accessToken
   * @returns {void}
   */
  async updateUserLocation(accessToken) {
    this.userSnapshotLocationListener = await this.getUserDoc().then(
      async (user) => {
        if (user.data().email) {
          const updatedUser = UsersService.toDocClass(user);
          let officeString = await this.retrieveUserOfficeLocation(accessToken);
          let offices = await this.getOffices();
          let office = offices.docs.find(
            (doc) => doc.data().HRV.toLowerCase() === officeString.toLowerCase()
          );
          if (office) {
            updatedUser.officeLocation = office.ref;
          } else {
            updatedUser.officeLocation = await this.addNewOfficeLocation(
              officeString
            );
          }
          await UsersService.updateOne(updatedUser);
        }
      }
    );
  }

  async getUserDoc() {
    return await getDoc(UsersService.getDocById(this.getUserId()));
  }
  async getOffices() {
    return await getDocs(collection(getFirestore(), "offices"));
  }

  async addNewOfficeLocation(officeHRV) {
    let officeObject = {
      HRV: officeHRV.charAt(0).toUpperCase() + officeHRV.slice(1),
      CRV: officeHRV
        .toUpperCase()
        .normalize("NFKD")
        .replace(/[\u0300-\u036F]/g, "")
        .replace(/\W/g, ""),
    };
    let officeCollection = collection(getFirestore(), "offices");
    await LogService.create(
      new LogEntryData({
        officeObject,
        collectionId: officeCollection.id,
      })
    );
    return addDoc(officeCollection, officeObject);
  }

  /**
   * Catches the login redirect from Firebase Auth.
   * @returns {Promise<void>}
   */
  async catchRedirect() {
    const auth = getAuth();
    const result = await getRedirectResult(auth);
    if (result && result.user !== null) {
      const user = await UsersService.getOne(this.getUserId());
      if (!user?.officeLocation) {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        await this.updateUserLocation(credential.accessToken);
      }
    }
  }

  /**
   * Retrieves the users location from Google.
   * @param {string} accessToken
   * @returns {Promise<string>}
   */
  async retrieveUserOfficeLocation(accessToken) {
    if (accessToken === "FirebaseAuthEmulatorFakeAccessToken_google.com") {
      return "Karlskrona";
    }
    const instance = axios.create();
    const response = await instance.get("people/me", {
      baseURL: "https://people.googleapis.com/v1/",
      timeout: 1000,
      headers: {
        authorization: "Bearer " + accessToken,
        accept: "application/json",
      },
      params: {
        personFields: "organizations",
      },
    });
    return response.data.organizations[0].location;
  }

  /**
   * Reset all state to its initial values.
   * @returns {void}
   */
  resetState() {
    Object.assign(this._state, {
      role: null,
    });
  }

  /**
   * @returns {void}
   */
  async signInWithRedirect() {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({ hd: "softhouse.se" });

    provider.addScope("email");
    provider.addScope("profile");
    provider.addScope("https://www.googleapis.com/auth/user.organization.read");

    const auth = getAuth();
    await signInWithRedirect(auth, provider);
  }

  /**
   * @returns {Promise<void>}
   */
  async logout() {
    if (this.unsubscribeUserRoleListener) this.unsubscribeUserRoleListener();

    await signOut(getAuth());
  }

  /**
   * @returns {firebase.User}
   */
  getUser() {
    return getAuth().currentUser;
  }

  /**
   * @returns {UserRole} role
   */
  getUserRole() {
    return this._state.role;
  }

  /**
   * @returns {string} user id
   */
  getUserId() {
    return getAuth().currentUser?.uid || "invalid user";
  }

  /**
   * Update the internal state with the current role.
   * @returns {Promise<void>}
   */
  async updateUserState() {
    const { claims } = await this.getUser().getIdTokenResult(true);
    if (Object.values(UserRole).includes(claims.role)) {
      this._state.role = claims.role;
    }
    if (!this.unsubscribeUserRoleListener)
      this.unsubscribeUserRoleListener = this.userRoleListener();
  }

  userRoleListener() {
    const getDoc = UsersService.getDocById(this.getUserId());
    return onSnapshot(getDoc, async (user) => {
      const { role } = user.data();
      if (this._state.role !== role) {
        await this.updateUserState();
        window.location.reload();
      }
    });
  }

  /**
   * If the user is any kind of Admin this will trigger.
   *
   * @returns {boolean}
   */
  isAdmin() {
    return (
      this.getUserRole() === UserRole.ADMIN ||
      this.getUserRole() === UserRole.OFFICEADMIN
    );
  }

  /**
   * If the user is super Admin this will trigger.
   *
   * @returns {boolean}
   */
  isSuperAdmin() {
    return this.getUserRole() === UserRole.ADMIN;
  }

  /**
   * If the user is office Admin this will trigger.
   *
   * @returns {boolean}
   */
  isOfficeAdmin() {
    return this.getUserRole() === UserRole.OFFICEADMIN;
  }

  /**
   * If the user role is null the user will be treated as a user.
   *
   * @returns {boolean}
   */
  isUser() {
    return this.getUserRole() === null || this.getUserRole() === UserRole.USER;
  }
})();
