<template>
  <button class="button is-blob" @click="onClickSignIn">
    <slot>Sign in</slot>
  </button>
</template>

<script>
import { AuthService } from "../../services/AuthService";

export default {
  name: "SignInButton",
  methods: {
    async onClickSignIn() {
      AuthService.signInWithRedirect();
    },
  },
};
</script>
