import { FirestoreService } from "./FirestoreService";
import { UsersService } from "./UsersService";
import { AuthService } from "./AuthService";
import { AssetsService } from "./AssetsService";
import {
  // eslint-disable-next-line no-unused-vars
  SupportRequestsFilter,
  SupportRequestType,
  SupportRequestStatus,
  UserRole,
} from "@/models";
import { CacheService } from "./CacheService";
import { getDocs, query, where } from "firebase/firestore";

export class SupportRequestsFirestoreService extends FirestoreService {
  async getRequests() {
    if (AuthService.isAdmin()) {
      return this.getAll();
    } else {
      return this.getUserRequests(AuthService.getUserId());
    }
  }

  /**
   * @param {string} userId
   */
  // TODO: Does this actually get the requests?
  async getUserRequests(userId) {
    return this.getAllWhere(
      "requestedByUserRef",
      "==",
      UsersService.getDocById(userId)
    );
  }

  /**
   *
   * @param {SupportRequestsFilter} filter
   */
  async getAllFromSupportRequestsFilter(filter) {
    const whereClauses = [];

    if (AuthService.isUser()) {
      whereClauses.push(
        where(
          "assetUserRef",
          "==",
          UsersService.getDocById(AuthService.getUserId())
        )
      );
    }
    if (AuthService.getUserRole() !== UserRole.ADMIN) {
      const user = await UsersService.getOne(AuthService.getUserId());

      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices)
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }

    if (filter.status.length > 0) {
      whereClauses.push(where("status", "in", filter.status));
    }

    if (filter.type) {
      whereClauses.push(where("type", "==", filter.type));
    }

    const q = query(this.getCollection(), ...whereClauses);
    const resolvedDocs = await getDocs(q);
    let supportRequests = this.toDocClasses(resolvedDocs);

    if (filter.requestedBy) {
      let users = await CacheService.getUsers();
      users = users.filter((user) => {
        return user.displayName
          .toLowerCase()
          .includes(filter.requestedBy.toLowerCase());
      });
      let supportRequestUserReferences = await this.getUserReferences(
        supportRequests
      );
      let userIds = users.map((user) => user.id);
      supportRequestUserReferences = supportRequestUserReferences.filter(
        (supportRequestUserRef) => {
          return userIds.includes(supportRequestUserRef);
        }
      );
      supportRequests = supportRequests.filter((s) =>
        supportRequestUserReferences.includes(s.userRef.id)
      );
    }

    if (filter.resourceSearch) {
      const resources = (await CacheService.getResources()).filter(
        (resource) => {
          return (resource.manufacturer + " " + resource.model)
            .toLowerCase()
            .includes(filter.resourceSearch.toLowerCase());
        }
      );
      const assetIds = CacheService.getAssets()
        .filter((asset) =>
          resources.find((resource) => resource.id === asset.resourceRef.id)
        )
        .map((asset) => asset.id);

      const supportRequestAssetReferences = (
        await this.getAssetReferences(supportRequests)
      ).filter((supportRequestAssetRef) => {
        return assetIds.includes(supportRequestAssetRef);
      });
      supportRequests = supportRequests.filter((supportRequest) =>
        supportRequestAssetReferences.includes(supportRequest.assetRef.id)
      );
    }

    if (filter.leader) {
      const userIds = (
        await UsersService.getUsersByLeaderId(filter.leader)
      ).map((user) => {
        return user.id;
      });

      supportRequests = supportRequests.filter((supportRequest) =>
        userIds.includes(supportRequest?.assetUserRef?.id)
      );
    }

    return supportRequests;
  }
  /**
   * @private
   * @param {string} assetId
   */
  async _getRequestsByAssetId(assetId) {
    return this.getAllWhere(
      "assetRef",
      "==",
      AssetsService.getDocById(assetId)
    );
  }

  /**
   * Reject support requests linked with the Asset id
   * @param {string} assetId
   */
  async deleteAllRequestsByAssetId(assetId) {
    const supportRequests = await this._getRequestsByAssetId(assetId);
    supportRequests.map((request) => this.deleteOne(request.id));
  }
  isApprovedDeletionRequest(supportRequest) {
    return (
      supportRequest.type === SupportRequestType.ASSET_DELETION &&
      supportRequest.status === SupportRequestStatus.APPROVED
    );
  }

  async getUserReferences(supportRequests) {
    return supportRequests
      .map((s) => (s.userRef = CacheService.getUser(s.requestedByUserRef)))
      .map((u) => u.id);
  }

  async getAssetReferences(supportRequests) {
    return supportRequests
      .map((supportRequest) => CacheService.getAsset(supportRequest.assetRef))
      .map((resource) => resource.id);
  }
}
