import { DocumentResolver } from "./DocumentResolver";
import { User } from "./User";

/**
 * @enum {string} AssetStatus
 */
export const KeyStatus = {
  ASSIGNED: "ASSIGNED",
  UNASSIGNED: "UNASSIGNED",
};

/**
 * Returns the Human Readable Value(HRV) for a given status.
 *
 * @param {AssetStatus} status
 */
export function getHRVForKeyStatus(status) {
  const values = {
    [KeyStatus.ASSIGNED]: "Assigned",
    [KeyStatus.UNASSIGNED]: "Unassigned",
  };

  return values[status];
}

/**
 * @class Key
 */
export class Key extends DocumentResolver {
  /**
   * @param {{
   *  id: string;
   *  userRef: firestore.DocumentReference<firestore.DocumentData>;
   *  serialNumber: string | null;
   *  status: KeyStatus;
   *  officeLocation: firestore.DocumentReference<firestore.DocumentData>;
   *  changedDate: DateTime;
   *  changedBy: firestore.DocumentReference<firestore.DocumentData>;
   * }} parameters
   */
  constructor({
    id = "",
    userRef = "",
    serialNumber = "",
    status,
    officeLocation,
    changedDate = "",
    changedBy,
  } = {}) {
    super();
    this.id = id;
    this.userRef = userRef;
    this.serialNumber = serialNumber;
    this.status = status;
    this.officeLocation = officeLocation;
    this.changedDate = changedDate;
    this.changedBy = changedBy;
  }

  /**
   * Returns the resolved ref data in an array structure.
   *
   * The return value can be destructured like:
   *
   * `const [user] = await key.resolveRefs();`
   *
   * @function
   * @async
   * @returns {[User]} data
   */
  async resolveRefs() {
    return Promise.all([this.resolveRef(User, "userRef")]);
  }
}
