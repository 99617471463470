<template>
  <div id="app">
    <router-view />

    <the-navbar />
    <the-modal-container />
    <the-alert-container />
  </div>
</template>

<script>
import TheNavbar from "./components/app/TheNavbar.vue";
import TheModalContainer from "./components/app/TheModalContainer";
import TheAlertContainer from "./components/app/TheAlertContainer";
import { addClass } from "./utils";

export default {
  name: "App",
  components: {
    TheAlertContainer,
    TheNavbar,
    TheModalContainer,
  },
  created() {
    addClass(document.body, "has-navbar-fixed-bottom");
  },
};
</script>
<style>
.firebase-emulator-warning {
  position: fixed;
  top: 0;
  left: 0;
  height: 23px;
  border: none !important;
}
</style>
