import { createApp } from "@/createApp";
import "@/registerServiceWorker";
import { Autocomplete, Dropdown, Field } from "buefy";
import Vue from "vue";
import "./assets/main.scss";
import "./firebase";
import { AlertService } from "@/services";

Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(Field);

Vue.config.errorHandler = function (error, vm, info) {
  const message = `Woops, something didn't go as planned but we've managed to catch the following error "${error.message}".
If the issue persist please contact a human at "it.karlskrona@softhouse.se" with the following information:

Error: ${error}
Component: ${vm.$options.name}
Additional information: ${info}

You may close this message at any time.
`;
  AlertService.alert(message, null, "is-warning");

  console.error(error);
};
// eslint-disable-next-line no-undef
Vue.prototype.$appVersion = VERSION;

Vue.config.productionTip = false;

createApp();
