// eslint-disable-next-line no-unused-vars
import { UserRole, User } from "@/models";
import { LogService } from "./LogService";
import { LogEntryData } from "@/models/LogEntryData";
import { AuthService } from "./AuthService";
import { UsersService } from "./UsersService";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  writeBatch,
  where,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

export class FirestoreService {
  /**
   * @template C
   * @param {string} collectionName
   * @param {C} DocClass
   */
  constructor(collectionName, DocClass) {
    this.collectionName = collectionName;
    this.DocClass = DocClass;
    this.observers = [];
    this.isListeningToSnapshot = false;
    this.db = getFirestore();
  }

  /**
   * @async
   * @private
   * @param {firestore.DocumentReference<firestore.DocumentData>[]} userRefs
   * @return {Promise<String[]>} userRefsAsString
   */
  async _getUserReferences(userRefs) {
    // Dynamically import due to trying to access Firestore before being instantiated
    const { CacheService } = await import("@/services/CacheService");
    return userRefs.map((a) => (a = CacheService.getUser(a))).map((u) => u.id);
  }

  /**
   *
   * @async
   * @private
   * @param {User[]} users
   * @param {C[]} docClasses
   * @returns {Promise<C[]>} C[]
   */
  async _filterByUserRef(users, docClasses) {
    let userRefs = docClasses.map((a) => a.userRef);
    let userRefsAsString = await this._getUserReferences(userRefs);
    let userIds = users.map((user) => user.id);

    userRefsAsString = userRefsAsString.filter((userRef) => {
      return userIds.includes(userRef);
    });

    docClasses = docClasses.map((doc) => {
      if (doc.userRef === null) {
        doc.userRef = "";
      }
      return doc;
    });
    return docClasses.filter((doc) => {
      return userRefsAsString.includes(doc.userRef.id);
    });
  }

  /**
   *
   * @private
   */
  _setCollectionObserver() {
    const collectionRef = collection(this.db, this.collectionName);
    onSnapshot(collectionRef, () => {
      this.observers.forEach((observer) => observer());
    });
  }

  /**
   * @function
   * @returns {firestore.CollectionReference<firestore.DocumentData>} collection
   */
  getCollection() {
    return collection(this.db, this.collectionName);
  }

  /**
   * @function
   * @param {string} id
   * @returns {firestore.DocumentReference<firestore.DocumentData>} doc
   */
  getDocById(id) {
    return doc(this.db, this.collectionName, id);
  }

  /**
   * Observe changes in the collection.
   * When the observer is not needed it must be manually removed with the removeObserver() method.
   * @function
   * @param {Function} observer
   */
  addObserver(observer) {
    if (!this.isListeningToSnapshot) {
      this._setCollectionObserver();
      this.isListeningToSnapshot = true;
    }
    this.observers.push(observer);
  }

  /**
   * Remove observer
   * @function
   * @param {Function} observer
   */
  removeObserver(observer) {
    this.observers = this.observers.filter((obs) => obs !== observer);
  }

  /**
   * @function
   * @param {firestore.DocumentSnapshot<firestore.DocumentData> | firestore.QueryDocumentSnapshot<firestore.DocumentData>} doc
   * @returns {C} data
   */
  toDocClass(doc) {
    return new this.DocClass({ id: doc.id, ...doc.data() });
  }

  /**
   *
   * @param {firestore.QuerySnapshot<firestore.DocumentData>} resolvedDocs
   */
  toDocClasses(resolvedDocs) {
    const asClasses = [];
    resolvedDocs.docs.forEach((doc) => asClasses.push(this.toDocClass(doc)));
    return asClasses;
  }

  /**
   * @function
   * @async
   * @returns {Promise<C[]>}
   * @param {string | firestore.FieldPath} fieldPath
   * @param {string} opStr
   * @param {any} value
   */
  async getAllWhere(fieldPath, opStr, value) {
    const q = query(this.getCollection(), where(fieldPath, opStr, value));
    const querySnapshot = await getDocs(q);
    return this.toDocClasses(querySnapshot);
  }

  /**
   * @function
   * @async
   * @returns {Promise<C[]>}
   * @param {Array} whereClauses Array of firestore where clauses to be put into a query
   */

  async getWithWhereClauses(whereClauses) {
    const q = query(this.getCollection(), ...whereClauses);
    const resolveDocs = await getDocs(q);
    return this.toDocClasses(resolveDocs);
  }

  /**
   * @function
   * @async
   * @returns {Promise<C[]>}
   */
  async getAll(overrideOfficeAdminRestrictions) {
    if (AuthService.isUser()) {
      const officeLocation = await UsersService.getUserOffice();
      return await this.getAllWhere("officeLocation", "==", officeLocation);
    } else if (
      overrideOfficeAdminRestrictions !== true &&
      AuthService.isOfficeAdmin()
    ) {
      const user = await UsersService.getOne(AuthService.getUserId());

      if (user.administeredOffices.length > 1) {
        return await this.getAllWhere(
          "officeLocation",
          "in",
          user.administeredOffices
        );
      } else {
        return await this.getAllWhere(
          "officeLocation",
          "==",
          user.officeLocation
        );
      }
    }

    const resolvedDocs = await getDocs(this.getCollection());
    return this.toDocClasses(resolvedDocs);
  }

  /**
   * @function
   * @async
   * @param {string} id
   * @returns {Promise<C>} data
   */
  async getOne(id) {
    const resolvedDoc = await getDoc(this.getDocById(id));
    return this.toDocClass(resolvedDoc);
  }

  /**
   * @function
   * @async
   * @param id
   * @param {C} data
   * @returns {Promise<firestore.DocumentReference<firestore.DocumentData>>} doc
   */
  // eslint-disable-next-line no-unused-vars
  async createOne({ id, ...data }) {
    await LogService.create(
      new LogEntryData({
        data,
        collectionId: this.getCollection().id,
      })
    );
    return await addDoc(this.getCollection(), data);
  }

  /**
   * @function
   * @async
   * @param id
   * @param {C} data
   * @returns {Promise<void>}
   */
  async updateOne({ id, ...data }) {
    await LogService.update(
      new LogEntryData({
        documentRef: await this.getDocById(id),
        data,
        collectionId: this.getCollection().id,
      })
    );
    const batch = writeBatch(this.db);
    batch.set(this.getDocById(id), data);
    await batch.commit();
  }

  /**
   * @function
   * @async
   * @param {string} id
   * @returns {Promise<void>}
   */
  async deleteOne(id) {
    await LogService.delete(
      new LogEntryData({
        documentRef: this.getDocById(id),
        collectionId: this.getCollection().id,
      })
    );
    await deleteDoc(this.getDocById(id));
  }
}
