import { DateTime } from "luxon";
// eslint-disable-next-line no-unused-vars
import { AssetStatus, QuarterDateTime } from "../models";
import { CacheService } from "./";

export const DashboardService = new (class {
  getDefaultPeriod() {
    const today = DateTime.local();
    return new QuarterDateTime({
      start: today.startOf("year"),
      end: today.endOf("year"),
    });
  }

  /**
   * @param {Asset[]} assets
   * @param {Resource} resource
   */
  isResourceUsed(assets, resource) {
    return assets.some((asset) => asset.resourceRef?.id === resource.id);
  }

  /**
   * @param {Asset} asset
   *
   * @returns {DateTime} date
   */
  getAssetExpiryDate(asset) {
    return DateTime.fromISO(asset.dateOfPurchase).plus({
      year: asset.yearsOfWarranty,
    });
  }
  /**
   * @param {Resource[]} resources
   * @param {Asset[]} assets
   *
   * @returns {Resource[]} resources
   */
  getUsedResources(resources, assets) {
    return resources.filter((resource) =>
      this.isResourceUsed(assets, resource)
    );
  }

  /**
   * @param {Resource[]} resources
   * @param {Asset[]} assets
   *
   * @returns {Resource[]} resources
   */
  getUnusedResources(resources, assets) {
    return resources.filter(
      (resource) => !this.isResourceUsed(assets, resource)
    );
  }

  /**
   * @param {Asset[]} assets
   *
   * @returns {Asset[]} assets
   */
  getAssignedAssets(assets) {
    return assets.filter((asset) => asset.status === AssetStatus.ASSIGNED);
  }

  /**
   * @param {Asset[]} assets
   *
   * @returns {Asset[]} assets
   */
  getUnassignedAssets(assets) {
    return assets.filter((asset) => asset.status === AssetStatus.UNASSIGNED);
  }

  /**
   * @param {Asset[]} assets
   *
   * @returns {Asset[]} assets
   */
  getRetiredAssets(assets) {
    return assets.filter((asset) => asset.status === AssetStatus.RETIRED);
  }

  /**
   * @param {Asset[]} assets
   *
   * @returns {Asset[]} assets
   */
  getRecycledAssets(assets) {
    return assets.filter((asset) => asset.status === AssetStatus.RECYCLED);
  }

  /**
   * @param {Asset[]} assets
   *
   * @returns {Asset[]} assets
   */
  getExpiredAssets(assets) {
    return assets.filter((asset) => {
      const expiryDate = this.getAssetExpiryDate(asset);
      const today = DateTime.local();
      return expiryDate <= today;
    });
  }

  /**
   * @param {Asset[]} assets
   * @param {QuarterDateTime} quarterDateTime
   *
   * @returns {Asset[]} assets
   */
  getAssetsWithin(assets, { start, end }) {
    return assets.filter((asset) => {
      const expiryDate = this.getAssetExpiryDate(asset);
      return expiryDate >= start && expiryDate <= end;
    });
  }

  /**
   * @param {Asset[]} assets
   * @param {ResourceType} assets
   *
   * @returns {Asset[]} assets
   */
  getAssetsOfType(assets, resourceType) {
    return assets.filter(
      (asset) =>
        CacheService.getResource(asset.resourceRef).type === resourceType
    );
  }

  /**
   * @param {Asset[]} assets
   *
   * @returns {User[]} users
   */
  getUsersWithExpiredAssets(assets) {
    const users = assets.map(({ userRef }) => CacheService.getUser(userRef));
    const uniqueUsers = [...new Set(users)].filter(({ id }) => id);

    return uniqueUsers;
  }

  /**
   * @param {Asset[]} assets
   * @param {String} userId
   *
   * @returns {Asset[]} assets
   */
  getAssetsOfUser(assets, userId) {
    return assets.filter(
      ({ userRef }) => CacheService.getUser(userRef).id === userId
    );
  }
})();
