import { FirestoreService } from "./FirestoreService";
// eslint-disable-next-line no-unused-vars
import { UserRole, ResourcesFilter } from "@/models";
import { CacheService, UsersService } from "../services";
import { AuthService } from "@/services/AuthService";
import { commonCharactersAreEqual } from "@/utils";
import { getDocs, query, where } from "firebase/firestore";

export class ResourcesFirestoreService extends FirestoreService {
  /**
   *  Overrides our generic getAll function to not filter out resources by office location
   */
  async getAll() {
    return super.getAll(true);
  }

  /**
   *
   * @param {ResourcesFilter} filter
   */
  async getAllFromResourcesFilter(filter) {
    const whereClauses = [];

    if (filter.location.length > 0) {
      const officeObjectArray = filter.location.map((office) => office.toRef());
      whereClauses.push(where("officeLocation", "in", officeObjectArray));
    }
    if (AuthService.getUserRole() === UserRole.OFFICEADMIN) {
      const user = await UsersService.getOne(AuthService.getUserId());

      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices)
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }

    if (filter.type) {
      whereClauses.push(where("type", "==", filter.type));
    }

    const q = query(this.getCollection(), ...whereClauses);
    const resolvedDocs = await getDocs(q);
    let resources = this.toDocClasses(resolvedDocs);

    // ? Perform substring comparison in the client as Firestore do not support this natively.
    if (filter.searchTerm) {
      resources = resources.filter((resource) => {
        const isManufacturerMatch = resource.manufacturer
          .toLowerCase()
          .includes(filter.searchTerm.toLowerCase());
        const isModelMatch = resource.model
          .toLowerCase()
          .includes(filter.searchTerm.toLowerCase());

        return isManufacturerMatch || isModelMatch;
      });
    }

    return resources;
  }

  /**
   *  checks if resource already exists,
   *  if true return the existing resource
   *
   * @param {Resource} newResource
   * @returns {Resource}
   */
  checkResourceAlreadyExists(newResource) {
    const existingResources = CacheService.getResources();
    return existingResources.find((resource) => {
      return (
        commonCharactersAreEqual(
          CacheService.getOffice(resource.officeLocation).CRV,
          CacheService.getOffice(newResource.officeLocation).CRV
        ) &&
        commonCharactersAreEqual(
          resource.manufacturer,
          newResource.manufacturer
        ) &&
        commonCharactersAreEqual(resource.model, newResource.model)
      );
    });
  }
}
