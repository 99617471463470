/**
 * @enum {string} SupportRequestType
 */
import { DocumentResolver } from "@/models/DocumentResolver";
import { DateTime } from "luxon";
import { Asset } from "./Asset";
import { User } from "./User";

/**
 * Returns the Human Readable Value(HRV) for a given type.
 * @param {SupportRequestType} type
 * @return {string}
 */
export function getHRVForSupportRequestType(type) {
  const values = {
    [SupportRequestType.ASSET_DELETION]: "Deletion request",
    [SupportRequestType.HARDWARE_ISSUE]: "Hardware issue",
  };
  return values[type];
}

/**
 * @enum {string} SupportRequestType
 */
export const SupportRequestType = {
  ASSET_DELETION: "ASSET_DELETION",
  HARDWARE_ISSUE: "HARDWARE_ISSUE",
};

/**
 * @enum {string} SupportRequestStatus
 */
export const SupportRequestStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CREATED: "CREATED",
};

/**
 * Returns the Human Readable Value(HRV) for a given status.
 *
 * @param {SupportRequestStatus} status
 * @return {string}
 */
export function getHRVForSupportRequestStatus(status) {
  const values = {
    [SupportRequestStatus.REJECTED]: "Rejected",
    [SupportRequestStatus.PENDING]: "Pending",
    [SupportRequestStatus.APPROVED]: "Approved",
    [SupportRequestStatus.CREATED]: "Created",
  };
  return values[status];
}

export class SupportRequest extends DocumentResolver {
  /**
   *
   * @param {{
   * id: string;
   * assetRef: firestore.DocumentReference<firestore.DocumentData>;
   * requestedByUserRef: firestore.DocumentReference<firestore.DocumentData>;
   * assetUserRef: firestore.DocumentReference<firestore.DocumentData>;
   * type: SupportRequestType;
   * status: SupportRequestStatus;
   * reason: string | null;
   * response: string | null;
   * requestDate: string;
   * officeLocation: firestore.DocumentReference<firestore.DocumentData>,
   * }} parameters
   */
  constructor({
    id = "",
    assetRef,
    requestedByUserRef,
    assetUserRef,
    type,
    status,
    reason = null,
    response = null,
    requestDate = DateTime.local().toISODate(),
    officeLocation,
  } = {}) {
    super();

    this.id = id;
    this.assetRef = assetRef;
    this.requestedByUserRef = requestedByUserRef;
    this.assetUserRef = assetUserRef;
    this.type = type;
    this.status = status;
    this.reason = reason;
    this.response = response;
    this.requestDate = requestDate;
    this.officeLocation = officeLocation;
  }

  /**
   * Returns the resolved ref data in an array structure.
   *
   * The return value can be destructured like:
   *
   * `const [asset, requestedbyUser, assetUser] = await supportRequest.resolveRefs();`
   *
   * @function
   * @async
   * @returns {[Asset, User, User]} data
   */
  async resolveRefs() {
    return Promise.all([
      this.resolveRef(Asset, "assetRef"),
      this.resolveRef(User, "requestedByUserRef"),
      this.resolveRef(User, "assetUserRef"),
    ]);
  }
}
