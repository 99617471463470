// eslint-disable-next-line no-unused-vars
import { KeysFilter } from "@/models/KeysFilter";
import { FirestoreService } from "./FirestoreService";
import { UsersService } from "./UsersService";
import { AuthService } from "@/services/AuthService";
import { UserRole } from "@/models";
import { getDocs, query, where } from "firebase/firestore";
import { CacheService } from "@/services/CacheService";

export class KeysFirestoreService extends FirestoreService {
  async getKeys() {
    return this.getAll();
  }
  /**
   * @param {string} serialNumber
   */
  async getKeysBySerialNumber(serialNumber) {
    return this.getAllWhere("serialNumber", "==", serialNumber);
  }
  /**
   * @param {string} officeLocation
   */
  async getOfficeKeys(officeLocation) {
    return this.getAllWhere("officeLocation", "==", officeLocation);
  }
  /**
   * @param {string} userId
   */
  async getUserKeys(userId) {
    return this.getAllWhere("userRef", "==", UsersService.getDocById(userId));
  }

  /**
   * @param {KeysFilter} filter
   * @returns {Promise<KeysFilter>}
   */
  async getAllFromKeysFilter(filter) {
    const whereClauses = [];

    if (AuthService.isUser()) {
      whereClauses.push(
        where("userRef", "==", UsersService.getDocById(AuthService.getUserId()))
      );
    } else if (AuthService.getUserRole() !== UserRole.ADMIN) {
      const officeLocation = await UsersService.getUserOffice();
      whereClauses.push(where("officeLocation", "==", officeLocation));
    }

    if (filter.locationSearch.length > 0) {
      const officeObjectArray = filter.locationSearch.map((office) =>
        office.toRef()
      );
      whereClauses.push(where("officeLocation", "in", officeObjectArray));
    }

    if (filter.statusSearch.length > 0) {
      whereClauses.push(where("status", "in", filter.statusSearch));
    }

    const q = query(this.getCollection(), ...whereClauses);
    const resolvedDocs = await getDocs(q);
    let keys = this.toDocClasses(resolvedDocs);

    if (filter.employeeSearch) {
      let users = await CacheService.getUsers();
      users = users.filter((user) => {
        return user.displayName
          .toLowerCase()
          .includes(filter.employeeSearch.toLowerCase());
      });

      keys = await this._filterByUserRef(users, keys);
    }

    return keys;
  }
}
